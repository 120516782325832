import IMask from 'imask';
// Получение данных из формы
const getFormData = (formElement) => {
    const formData = new FormData(formElement);
    const data = {
        name: formData.get('name'),
        phone: formData.get('phone')
    };
    return data;
};
// Отправление заявки
const sendBid = async (formData, showSuccessMessage, showErrorMessage) => {
    const requestBody = {
        bid: {
            name: formData.name,
            phone: formData.phone
        }
    };
    try {
        const response = await fetch('/api/v1/bids', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: { 'Content-type': 'application/json; charset=UTF-8' }
        });
        if (response.ok)
            showSuccessMessage();
        else
            showErrorMessage();
    }
    catch {
        showErrorMessage();
    }
};
export const setupForm = (parentId) => {
    const { phoneInput, form, nameInput, submitButton, hideForm, showForm, hideLoader, showLoader, showSuccessMessage, closeErrorMessageButton, hideErrorMessage, showErrorMessage } = getElements(parentId);
    // Маска для поля ввода телефона
    const mask = IMask(phoneInput, { mask: '+7 (000) 000 - 00 - 00' });
    // Обработка изменений в форме
    form.addEventListener('input', () => {
        const name = nameInput.value;
        const phone = mask.unmaskedValue;
        const isValidData = name.length > 0 && phone.length === 10;
        submitButton.disabled = !isValidData;
    });
    // Отправка формы по клику
    submitButton.addEventListener('click', async (event) => {
        event.preventDefault();
        hideForm();
        showLoader();
        await sendBid(getFormData(form), showSuccessMessage, showErrorMessage);
        hideLoader();
    });
    // Закрытие сообщения об успехе
    closeErrorMessageButton.addEventListener('click', () => {
        hideErrorMessage();
        showForm();
    });
};
const getElements = (parentId) => {
    const parent = document.querySelector(`#${parentId}`);
    // Form
    const form = parent.querySelector('form');
    const hideForm = () => form.classList.replace('flex', 'hidden');
    const showForm = () => form.classList.replace('hidden', 'flex');
    // Loader
    const loader = parent.querySelector('.formMakeAppointment01__loader');
    const hideLoader = () => loader.classList.replace('block', 'hidden');
    const showLoader = () => loader.classList.replace('hidden', 'block');
    // Success message
    const successMessage = parent.querySelector('.formMakeAppointment01__successMessage');
    const showSuccessMessage = () => successMessage.classList.replace('hidden', 'flex');
    // Error message
    const errorMessage = parent.querySelector('.formMakeAppointment01__errorMessage');
    const hideErrorMessage = () => errorMessage.classList.replace('flex', 'hidden');
    const showErrorMessage = () => errorMessage.classList.replace('hidden', 'flex');
    const closeErrorMessageButton = errorMessage.querySelector('button');
    const nameInput = form.querySelector('.formMakeAppointment01__nameInput');
    const phoneInput = form.querySelector('.formMakeAppointment01__phoneInput');
    const submitButton = form.querySelector('.formMakeAppointment01__submitButton');
    return {
        phoneInput,
        form,
        nameInput,
        submitButton,
        hideForm,
        showForm,
        hideLoader,
        showLoader,
        showSuccessMessage,
        closeErrorMessageButton,
        hideErrorMessage,
        showErrorMessage
    };
};
